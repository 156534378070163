import {SurveyJSON} from "Survey/types"

const json: SurveyJSON = {
  success: 'Færdig! Bemærk at du kun hører fra os, hvis vi er interesserede, så ikke vent på os.',
  allowNewCustomer: true,
  /*
  Fbtracking feature:
  calculateTrackingAmount?: (values: Values) => number, //if specified, this function will be used to set the value of trackingAmount
  trackingAmount?: number, //if trackingAmout value is present and positive, it will be send to facebook
  */
  calculateTrackingAmount: (values) => {
    if (values.hourly && !isNaN(values.hourly) && values.hourly < 360 && (values.competences && (values.competences.indexOf('bookkeeping') >= 0 || values.competences.indexOf('accounting') >= 0))) {
      return 300;
    } else if (values.hourly && !isNaN(values.hourly) && values.hourly < 160 && (values.competences && (values.competences.indexOf('onboarding') >= 0 || values.competences.indexOf('customer_service') >= 0))) {
      return 150;
    } else if (values.hourly && !isNaN(values.hourly) && values.hourly < 160 && (values.competences && values.competences.indexOf('marketing') >= 0)) {
      return 100;
    } else {
      return 0; //Meaning that no purchase conversion will be reported to Facebook
    }
  },
  errors: {
    required: 'Udfyld venligst dette'
  },
  locales: {
    dk: {
      nextButton: 'Fortsæt',
      nextButtonHint: 'tryk <b>Enter ↵</b>',
      submitButton: 'Indsend',
      textAreaHint: '<b>Shift ⇧</b> + <b>Enter ↵</b> to make a line break',
      invalidEmail: "Email adressen er ugyldig"
    }
  },
  defaultLocale: 'dk',
  items: [
    {
      type: 'checkboxes',
      name: 'sales_qualifications',
      title: 'Hvilke forudsætninger har du for at sælge til vores kunder?',
      required: true,
      multiple: true,
      options: [
        {
          text: 'Salgserfaring',
          value: 'sales_experience',
        }, {
          text: 'Bogføring i forhold til mindre virksomheder',
          value: 'accounting_knowledge_small_businesses',
        }, {
          text: 'Jeg har selv drevet virksomhed og/eller forstår mig på regnskaber',
          value: 'entrepreneur',
        }, {
          text: 'Evnen til at skabe et tillidsforhold på kort tid over telefonen',
          value: 'ability_to_build_trust_quickly_over_phone',
        }, {
          text: 'Ingen erfaring, men er interesseret i at lære',
          value: 'no_experience',
        }
      ]
    },{
      type: 'checkboxes',
      name: 'daytime',
      title: 'Kan du arbejde i de danske dagtimer?',
      required: true,
      multiple: false,
      options: [
        {
          text: 'Ja',
          value: 'daytime',
        }, {
          text: 'Delvist',
          value: 'partially_daytime',
        }, {
          text: 'Nej',
          value: 'not_daytime',
        },
      ]
    },{
      type: 'input',
      name: 'name',
      title: 'Hvad er dit navn',
      required: true,
      placeholder: 'Jens Jensen',
    },{
      type: 'input',
      inputType: 'email',
      name: 'email',
      title: 'Hvad er din email adresse?',
      required: true,
      placeholder: 'email@email.com',
    }, {
      type: 'input',
      name: 'hours',
      title: 'Hvor mange timer ønsker du at arbejde per uge',
      required: false,
      placeholder: '40',
      mask: '999',
    },{
      type: 'checkboxes',
      name: 'customer_pursuit_comfort',
      title: 'Er du komfortabel med at jagte kunder for at få et salg igennem?',
      required: true,
      multiple: false,
      options: [
        {
          text: 'Ja, uden tvivl',
          value: 'yes_no_doubt',
        }, {
          text: 'Ja, efter noget tilvænning',
          value: 'yes_after_some_adjustment',
        }, {
          text: 'Det er en interessant udfordring',
          value: 'interesting_challenge',
        }, {
          text: 'Det ville jeg have svært ved',
          value: 'difficult_for_me',
        }, {
          text: 'Nej, jeg mener at man ikke skal presse nogen til at købe noget',
          value: 'no_not_pressuring_customers',
        }
      ]
    },{

      type: 'checkboxes',
      name: 'decision_on_seller',
      title: 'En sælger har arbejdet i to år og koster 100 kroner i timen og får 10% af kundehenvendelserne til at betale for ydelsen. Sælgeren bruger 0,5 timer per kundehenvendelse. ' +
          'Hver kundehenvendelse koster 100 kroner i reklameomkostninger. Der er overskud på 1500 kroner på leverancen af ydelsen (dvs. alt efter at ydelsen er solgt). Hvad burde virksomheden gøre i denne situation?',
      required: true,
      multiple: false,
      options: [
        {
          text: 'Tilbyde sælgeren en højere timeløn',
          value: 'offer_higher_wage',
        }, {
          text: 'Afslutte samarbejdet med sælgeren',
          value: 'end_employment',
        }, {
          text: 'Øge prisen på produktet',
          value: 'increase_product_price',
        }, {
          text: 'Fortsætte uændret',
          value: 'continue_unchanged',
        }, {
          text: 'Tilbyde sælgeren et salgskursus og mentorordning',
          value: 'offer_sales_training_mentorship',
        }, {
          text: 'Reducere prisen på produktet',
          value: 'reduce_product_price',
        },
      ]
    }, {
      type: 'input',
      name: 'hourly',
      title: 'Hvis du arbejder som freelancer (hvor du fakturer os for dine timer), hvor mange kroner tager du i såfald per time (eks. evt. moms)?',
      required: false,
      placeholder: '100',
      mask: '999',
      validate: (values) => { return (!values.hourly || isNaN(values.hourly) ? "A number is required" : "")}
    },{
    type: 'custom',
    name: 'submit',
    html: '<blockquote>Tryk "indsend" for at sende os svarene.</blockquote>',
    buttons: ['submit']
    }
  ]
}

export default json
